<template>
  <div>
    <EyeTracker />
  </div>
</template>

<script>
import EyeTracker from "./components/EyeTracker.vue";

export default {
  components: {
    EyeTracker,
  },
};
</script>