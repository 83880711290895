<template>
  <div class="eye-tracker">
    <h2>双眼动态角度追踪与斜视检测</h2>
    <div class="video-container">
      <video ref="videoElement" autoplay playsinline class="video-feed"></video>
      <canvas ref="overlay" class="overlay"></canvas>
    </div>
    <div class="tracking-data">
      <p>左眼角度: {{ angles.left.toFixed(2) }}°</p>
      <p>右眼角度: {{ angles.right.toFixed(2) }}°</p>
      <p>
        瞳孔检测:
        <span :style="{ color: pupilsDetected ? 'green' : 'red' }">
          {{ pupilsDetected ? '已检测' : '未检测' }}
        </span>
      </p>
      <!-- <p>
        眼球角度同步:
        <span :style="{ color: isSynchronized ? 'green' : 'red' }">
          {{ isSynchronized ? '同步' : '不同步' }}
        </span>
      </p> -->
      <p>
        瞳孔位置同步:
        <span :style="{ color: isPupilsAligned ? 'green' : 'orange' }">
          {{ isPupilsAligned ? '同步' : '不同步' }}
        </span>
      </p>
      <p>
        斜视检测:
        <span :style="{ color: hasStrabismus ? 'red' : 'green' }">
          {{ hasStrabismus ? '检测到斜视' : '正常' }}
          (瞳孔差值:{{Math.abs(this.pupilPositionNormalized.left - this.pupilPositionNormalized.right).toFixed(2)}})
          (眼球角度差值:{{Math.abs(this.angles.left - this.angles.right).toFixed(2)}})
        </span>
      </p>
      <div class="pupil-position-data">
        <div>
          <p>左眼瞳孔相对位置: {{ pupilPositionNormalized.left.toFixed(2) }}</p>
          <div class="eye-position-indicator">
            <div class="eye-center"></div>
            <div class="pupil-marker" 
                 :style="{ left: `calc(50% + ${pupilPositionNormalized.left * 30}px)` }"></div>
          </div>
        </div>
        <div>
          <p>右眼瞳孔相对位置: {{ pupilPositionNormalized.right.toFixed(2) }}</p>
          <div class="eye-position-indicator">
            <div class="eye-center"></div>
            <div class="pupil-marker" 
                 :style="{ left: `calc(50% + ${pupilPositionNormalized.right * 30}px)` }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FaceMesh } from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";

export default {
  data() {
    return {
      angles: { left: 0, right: 0 }, // 双眼角度
      faceMesh: null, // Face Mesh 模型对象
      camera: null, // 摄像头流
      pupilPosition: { left: 0, right: 0 }, // 存储瞳孔相对位置（原始值）
      pupilPositionNormalized: { left: 0, right: 0 }, // 标准化的相对位置 (-1 到 1)
      eyeWidth: { left: 0, right: 0 }, // 眼睛宽度，用于归一化
      pupilsDetected: false, // 是否检测到瞳孔
      lastDetectionTime: 0, // 最后一次检测时间
    };
  },

  computed: {
    isSynchronized() {
      // 角度同步判断 - 小于5度视为同步
      return Math.abs(this.angles.left - this.angles.right) <= 5; 
    },
    
    isPupilsAligned() {
      // 瞳孔位置同步判断 - 标准化后相差小于0.2视为同步
      return this.pupilsDetected && 
        Math.abs(this.pupilPositionNormalized.left - this.pupilPositionNormalized.right) <= 0.2&&Math.abs(this.pupilPositionNormalized.right - this.pupilPositionNormalized.left) <= 0.2;
    },
    
    hasStrabismus() {
      // 斜视检测 - 基于瞳孔位置差异和角度差异


      return this.pupilsDetected && 
        (Math.abs(this.pupilPositionNormalized.left - this.pupilPositionNormalized.right) > 0.2 ||
         Math.abs(this.angles.left - this.angles.right) > 25||
         Math.abs(this.angles.right - this.angles.left) > 25);
    }
  },

  mounted() {
    this.initializeFaceMesh();
  },
  
  beforeUnmount() {
    // 清理资源
    if (this.camera) {
      this.camera.stop();
    }
    if (this.faceMesh) {
      this.faceMesh.close();
    }
  },

  methods: {
    async initializeFaceMesh() {
      const videoElement = this.$refs.videoElement;
      const overlay = this.$refs.overlay;

      overlay.width = 640;
      overlay.height = 480;

      this.faceMesh = new FaceMesh({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
      });
      this.faceMesh.setOptions({
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });

      this.faceMesh.onResults(this.onResults);

      this.camera = new cam.Camera(videoElement, {
        onFrame: async () => {
          await this.faceMesh.send({ image: videoElement });
        },
        width: 640,
        height: 480,
      });
      this.camera.start();
    },

    onResults(results) {
      if (!results.multiFaceLandmarks || results.multiFaceLandmarks.length === 0) {
        // 超过1秒没有检测到，设置为未检测状态
        if (Date.now() - this.lastDetectionTime > 1000) {
          this.pupilsDetected = false;
        }
        return;
      }

      this.lastDetectionTime = Date.now();
      this.pupilsDetected = true;

      const ctx = this.$refs.overlay.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const landmarks = results.multiFaceLandmarks[0];
      this.drawFaceMesh(ctx, landmarks);
      this.analyzeEyePositions(ctx, landmarks);
    },

    analyzeEyePositions(ctx, landmarks) {
      // 眼睛关键点定义
      // 左眼
      const leftEyeTop = landmarks[159]; // 左眼上边缘
      const leftEyeBottom = landmarks[145]; // 左眼下边缘
      const leftEyeLeft = landmarks[33]; // 左眼左边缘
      const leftEyeRight = landmarks[133]; // 左眼右边缘
      const leftPupil = landmarks[468]; // 左眼瞳孔（MediaPipe提供的瞳孔关键点）
      
      // 右眼
      const rightEyeTop = landmarks[386]; // 右眼上边缘
      const rightEyeBottom = landmarks[374]; // 右眼下边缘
      const rightEyeLeft = landmarks[362]; // 右眼左边缘
      const rightEyeRight = landmarks[263]; // 右眼右边缘
      const rightPupil = landmarks[473]; // 右眼瞳孔

      // 计算眼睛宽度
      this.eyeWidth.left = Math.abs(leftEyeRight.x - leftEyeLeft.x) * ctx.canvas.width;
      this.eyeWidth.right = Math.abs(rightEyeRight.x - rightEyeLeft.x) * ctx.canvas.width;
      
      // 计算眼睛中心
      const leftEyeCenterX = (leftEyeLeft.x + leftEyeRight.x) / 2;
      const leftEyeCenterY = (leftEyeTop.y + leftEyeBottom.y) / 2;
      const rightEyeCenterX = (rightEyeLeft.x + rightEyeRight.x) / 2;
      const rightEyeCenterY = (rightEyeTop.y + rightEyeBottom.y) / 2;
      
      // 计算瞳孔相对位置（像素）
      this.pupilPosition.left = (leftPupil.x - leftEyeCenterX) * ctx.canvas.width;
      this.pupilPosition.right = (rightPupil.x - rightEyeCenterX) * ctx.canvas.width;
      
      // 归一化瞳孔位置 (-1 到 1 范围)
      this.pupilPositionNormalized.left = this.eyeWidth.left > 0 ? 
        (leftPupil.x - leftEyeCenterX) / (this.eyeWidth.left / ctx.canvas.width) * 2 : 0;
      this.pupilPositionNormalized.right = this.eyeWidth.right > 0 ? 
        (rightPupil.x - rightEyeCenterX) / (this.eyeWidth.right / ctx.canvas.width) * 2 : 0;
      
      // 计算角度
      this.angles.left = this.calculateEyeAngle(leftPupil.x, ctx.canvas.width);
      this.angles.right = this.calculateEyeAngle(rightPupil.x, ctx.canvas.width);
      
      // 绘制眼睛中心和瞳孔
      this.drawEyeIndicators(ctx, {
        leftCenter: { x: leftEyeCenterX * ctx.canvas.width, y: leftEyeCenterY * ctx.canvas.height },
        rightCenter: { x: rightEyeCenterX * ctx.canvas.width, y: rightEyeCenterY * ctx.canvas.height },
        leftPupil: { x: leftPupil.x * ctx.canvas.width, y: leftPupil.y * ctx.canvas.height },
        rightPupil: { x: rightPupil.x * ctx.canvas.width, y: rightPupil.y * ctx.canvas.height }
      });
    },
    
    drawFaceMesh(ctx, landmarks) {
      // 绘制所有面部特征点
      ctx.fillStyle = "rgba(0, 0, 255, 0.5)";
      landmarks.forEach((landmark) => {
        const x = landmark.x * ctx.canvas.width;
        const y = landmark.y * ctx.canvas.height;
        ctx.beginPath();
        ctx.arc(x, y, 1, 0, 2 * Math.PI);
        ctx.fill();
      });
      
      // 绘制眼睛轮廓
      const drawEyeContour = (indices) => {
        ctx.strokeStyle = "rgba(0, 255, 255, 0.8)";
        ctx.lineWidth = 1;
        ctx.beginPath();
        indices.forEach((idx, i) => {
          const point = landmarks[idx];
          const x = point.x * ctx.canvas.width;
          const y = point.y * ctx.canvas.height;
          if (i === 0) ctx.moveTo(x, y);
          else ctx.lineTo(x, y);
        });
        ctx.closePath();
        ctx.stroke();
      };
      
      // 左眼轮廓索引 (一个近似的左眼轮廓)
      const leftEyeIndices = [33, 7, 163, 144, 145, 153, 154, 155, 133, 173, 157, 158, 159, 160, 161, 246];
      // 右眼轮廓索引 (一个近似的右眼轮廓)
      const rightEyeIndices = [362, 382, 381, 380, 374, 373, 390, 249, 263, 466, 388, 387, 386, 385, 384, 398];
      
      drawEyeContour(leftEyeIndices);
      drawEyeContour(rightEyeIndices);
    },
    
    drawEyeIndicators(ctx, points) {
      // 绘制眼睛中心
      ctx.fillStyle = "rgba(0, 255, 0, 0.8)";
      ctx.beginPath();
      ctx.arc(points.leftCenter.x, points.leftCenter.y, 3, 0, 2 * Math.PI);
      ctx.fill();
      ctx.beginPath();
      ctx.arc(points.rightCenter.x, points.rightCenter.y, 3, 0, 2 * Math.PI);
      ctx.fill();
      
      // 绘制瞳孔
      ctx.fillStyle = "rgba(255, 0, 0, 1)";
      ctx.beginPath();
      ctx.arc(points.leftPupil.x, points.leftPupil.y, 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.beginPath();
      ctx.arc(points.rightPupil.x, points.rightPupil.y, 5, 0, 2 * Math.PI);
      ctx.fill();
      
      // 绘制从中心到瞳孔的线
      ctx.strokeStyle = "rgba(255, 255, 0, 0.8)";
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(points.leftCenter.x, points.leftCenter.y);
      ctx.lineTo(points.leftPupil.x, points.leftPupil.y);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(points.rightCenter.x, points.rightCenter.y);
      ctx.lineTo(points.rightPupil.x, points.rightPupil.y);
      ctx.stroke();
    },

    calculateEyeAngle(x, width) {
      const centerX = width / 2;
      const dx = (x * width) - centerX;
      // 计算角度，使用视野范围约70度进行校准
      return (Math.atan2(dx, width) * 180) / Math.PI * 2;
    },
  },
};
</script>
<style scoped>
.eye-tracker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container {
  position: relative;
}

.video-feed {
  display: block;
  width: 640px;
  height: 480px;
  border: 1px solid #000;
}

.overlay {
  position: absolute;
  width: 640px;
  height: 480px;
  top: 0;
  left: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0);
}

.tracking-data {
  margin-top: 20px;
  font-size: 18px;
}
</style>